/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import AppState from '@state/AppState';
import dayjs from 'dayjs';

import { getDownloadOrgExport, postOrgExport } from '@api/restricted/org-exports-api';

import { PUB_EXPORTS_EXPLANATIONS } from '@constants/supportingCopy';

import AlertMessage from '@ui-kit/alert/Alert';
import Header from '@ui-kit/typography/header';
import Text from '@ui-kit/typography/text';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import BaseSelect from '@ui-kit/inputs/baseSelect/BaseSelect';
import BaseTypeAhead from '@distinct-components/typeAheadInputs/baseTypeAhead';
import Box from '@ui-kit/box';
import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BreakLine from '@ui-kit/dividers/breakLine';
import BaseButton from '@ui-kit/buttons/baseButton';

import FullScreenModal from '@layouts/full-screen/full-screen-modal';

import { StyledDateWrapper } from './CreateExportStyles';

const d = dayjs().format();
const TODAY_DATE = d.substring(0, 10);

function CreateExport({
  handleClose,
  loadExports,
  // numCompsToExportCSV,
  // numCompsToExportCWR,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [exportType, setExportType] = useState('csv');
  const [error, setError] = useState('');
  const [isAllWorks, setIsAllWorks] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [selectedWriter, setSelectedWriter] = useState(null);
  const [showDateRanges, setShowDateRanges] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const activeClientConnections = AppState.orgConnections.value.filter((connection) => connection.isActive && connection.connectionIsClient);

  const onClickCreateExport = () => {
    const utcFromDate = (showDateRanges && fromDate) ? dayjs(fromDate) : null;
    const utcToDate = (showDateRanges && toDate) ? dayjs(toDate).endOf('day') : null;
    if (utcFromDate && utcToDate && utcFromDate.isAfter(utcToDate)) {
      setError('Enter a valid time frame');
      return;
    }

    const selectedConnectionId = selectedConnection ? parseInt(selectedConnection, 10) : null;
    const selectedWriterId = selectedWriter ? AppState.publisherActiveSongwriters.value.find((writer) => writer.name === selectedWriter)?.id : null;

    setError('');
    setIsLoading(true);

    postOrgExport(AppState.pubOrganization.id.value, exportType, isAllWorks, selectedConnectionId, selectedWriterId, utcFromDate, utcToDate)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            const exportId = json.id;
            const exportName = json.name;
            loadExports().then(() => {
              getDownloadOrgExport(AppState.pubOrganization.id.value, exportId).then((downloadResponse) => {
                if (downloadResponse.status === 200) {
                  downloadResponse.blob().then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = exportName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                  });
                }
                handleClose();
              });
            });
          });
        } else {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <FullScreenModal formWidth="23em" closeFunction={handleClose}>
      <Header mb="0.5em">Create Export</Header>
      <Text fontSize="0.813rem" mb="0.5rem" fontWeight="400" color="var(--text-secondary)">{PUB_EXPORTS_EXPLANATIONS.createExportInstruction}</Text>
      <Text fontSize="0.813rem" fontWeight="400" color="var(--text-secondary)">{PUB_EXPORTS_EXPLANATIONS.createExportHowToRevert}</Text>
      <BreakLine mt="1.5rem" mb="1.5rem" color="var(--panel-info-border)" />

      <InputLabel label="Export Type" />
      <Box display="flex" mb="1.5em" width="100%">
        <ButtonSegment onClick={() => setExportType('csv')} btnText="CSV" variant="first" active={exportType === 'csv'} fluid maxHeight="inherit" />
        <ButtonSegment onClick={() => setExportType('cwr/2.1')} btnText="CWR 2.1" variant="last" active={exportType === 'cwr/2.1'} fluid maxHeight="inherit" />
      </Box>

      <InputLabel label="Which Compositions" />
      <Box display="flex" mb="1.5em" width="100%">
        <ButtonSegment onClick={() => setIsAllWorks(false)} btnText="Not Exported" variant="first" active={!isAllWorks} fluid maxHeight="inherit" />
        <ButtonSegment onClick={() => setIsAllWorks(true)} btnText="All Works" variant="last" active={isAllWorks} fluid maxHeight="inherit" />
      </Box>

      {activeClientConnections.length > 0 && (
      <Box>
        <InputLabel label="Which Publisher (optional)" />
        <BaseSelect
          id="publisherRegistrationId"
          name="publisherRegistrationId"
          fluid
          mb="1.5rem"
          onChange={(e) => setSelectedConnection(e.target.value)}
        >
          <option value="" default selected>--All Publishers--</option>
          <option value={AppState.pubOrganization.id.value}>{AppState.pubOrganization.name.value}</option>
          {activeClientConnections.map((pub) => (
            <option value={pub.orgId}>{pub.name}</option>
          ))}
        </BaseSelect>
      </Box>
      )}

      <Box>
        <InputLabel label="Which Writer (optional)" />
        <BaseTypeAhead
          id="writers"
          data={AppState.publisherActiveSongwriters.value}
          nameValue="name"
          handleChange={(data) => setSelectedWriter(data)}
          initialValue=""
        />
      </Box>

      <InputLabel label="Date Written" />
      <Box display="flex" mb="1.5em" width="100%">
        <ButtonSegment onClick={() => setShowDateRanges(false)} btnText="Any Dates" variant="first" active={!showDateRanges} fluid maxHeight="inherit" />
        <ButtonSegment onClick={() => setShowDateRanges(true)} btnText="Between Dates" variant="last" active={showDateRanges} fluid maxHeight="inherit" />
      </Box>

      {showDateRanges && (
        <StyledDateWrapper>
          <Box className="dateBox" width="18em">
            <InputLabel label="From Date" />
            <BaseInput
              id="fromDate"
              name="fromDate"
              type="date"
              max={TODAY_DATE}
              fluid
              mb="2.5em"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Box>
          <BreakLine m="0em 1em 1em" width="10%" color="var(--panel-info-border)" />
          <Box className="dateBox" width="18em">
            <InputLabel label="To Date" />
            <BaseInput
              id="toDate"
              name="toDate"
              type="date"
              defaultValue={TODAY_DATE}
              max={TODAY_DATE}
              fluid
              mb="2.5em"
              onChange={(e) => setToDate(e.target.value)}
            />
          </Box>
        </StyledDateWrapper>
      )}

      {/* {!isAllWorks
      && (
      <Text>
        Total Unexported Compositions:
        {' '}
        {exportType === 'csv' ? numCompsToExportCSV : numCompsToExportCWR}
      </Text>
      )} */}

      {error && (
      <AlertMessage
        variant="negative"
        message={error}
        mb="1.5em"
      />
      )}

      <BreakLine mb="2rem" mt="1.5rem" color="var(--border)" />
      <Box display="flex" justifyContent="flex-end">
        <BaseButton
          mb="1.5em"
          btnText="Export Data"
          onClick={onClickCreateExport}
          disabled={isLoading}
          isLoading={isLoading}
          fluid
        />
      </Box>

    </FullScreenModal>
  );
}

CreateExport.propTypes = {
  handleClose: PropTypes.func.isRequired,
  loadExports: PropTypes.func.isRequired,
  // numCompsToExportCSV: PropTypes.number.isRequired,
  // numCompsToExportCWR: PropTypes.number.isRequired,
};

export default CreateExport;
