/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  useEffect, useState,
} from 'preact/hooks';
import copy from 'copy-to-clipboard';
import AppState from '@state';

import HeaderCard from '@distinct-components/cards/headerCard';
import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import Icon from '@ui-kit/icon';
import CopyIcon from '@assets/icons/copy-outline.svg';

import LyricItem from '@distinct-components/dataDisplay/lyricItem';

import {
  LyricViewWrapper,
} from './ViewCompositionLyricsStyles';

const paragraphBreakRegex = /(?:\r?\n){2,}/;

function ViewCompositionLyrics({ lyrics, isInstrumental, handleEditLyrics }) {
  const [lyricChunks, setLyricChunks] = useState();

  useEffect(() => {
    const chunks = lyrics ? lyrics.split(paragraphBreakRegex) : null;
    setLyricChunks(chunks);
  }, [lyrics]);

  const useToastAlert = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'success' };
  };

  const handleCopyToClipboard = () => {
    if (lyricChunks) {
      copy(lyricChunks.join('\n\n'));
      useToastAlert('Copied to Clipboard');
    }
  };

  return (
    <HeaderCard mb="1.5em" headerText="Lyrics" editFunction={handleEditLyrics}>
      <Box>
        {isInstrumental && <Text>Marked as Instrumental - No Lyrics</Text>}
      </Box>
      <Box position="relative">
        {lyricChunks && (
        <Box className="cursor-p" display="flex" alignItems="center" justifyContent="flex-end" position="absolute" right="0">
          <Icon size="1em" pt="3px" mb="0.25em" mr="0.5em"><CopyIcon /></Icon>
          <Text fontSize="0.75em" onClick={handleCopyToClipboard}>Copy to Clipboard</Text>
        </Box>
        )}

        {lyricChunks ? (
          <LyricViewWrapper pt="0.875em">
            {lyricChunks.map((chunk, index) => (
              <LyricItem
                lyricChunk={chunk}
                index={index}
                isLoading={false}
              />
            ))}
          </LyricViewWrapper>
        ) : (
          <Text color="var(--text-medium-mute)">No Lyrics Added</Text>
        )}
      </Box>
    </HeaderCard>
  );
}

ViewCompositionLyrics.propTypes = {
  lyrics: PropTypes.string,
  isInstrumental: PropTypes.bool.isRequired,
  handleEditLyrics: PropTypes.func.isRequired,
};

ViewCompositionLyrics.defaultProps = {
  lyrics: '',
};

export default ViewCompositionLyrics;
