/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';
import { Fragment } from 'preact';

import useIsoDateFormat from '@hooks/useIsoDateFormat';

import Box from '@ui-kit/box';
import BreakLine from '@ui-kit/dividers/breakLine';
import Text from '@ui-kit/typography/text';
import AiSummary from '@distinct-components/dataDisplay/aiSummary/AiSummary';

function AddedContractDataView({ contractSummary, contractData }) {
  const undefinedLabel = 'Not Found';
  const textValueMargin = '0.5em';

  const d = useIsoDateFormat;

  return (
    <Box>
      <AiSummary
        summary={contractSummary || 'Unable to create summary information'}
      />

      <BreakLine mb="1.25em" mt="1.5em" color="var(--panel-info-border)" />

      {contractData.writers.map((writer) => (
        <Fragment>
          <Text mb="0.813em" fontWeight="800">
            Writer Information
          </Text>

          <Box mb="0.875rem" key={writer.name}>
            <Text mb={textValueMargin} fontSize="0.813rem">
              Name:
              {' '}
              {writer.name || undefinedLabel}
            </Text>
            {writer.alias && (
            <Text mb={textValueMargin} fontSize="0.813rem">
              Persona:
              {' '}
              {writer.alias}
            </Text>
            )}
            <Text mb={textValueMargin} fontSize="0.813rem">
              Email:
              {' '}
              {writer.email || undefinedLabel}
            </Text>
            <Text mb={textValueMargin} fontSize="0.813rem">
              Address:
              {' '}
              {writer.address || undefinedLabel}
            </Text>
            <Text mb={textValueMargin} fontSize="0.813rem">
              Country:
              {' '}
              {writer.country || undefinedLabel}
            </Text>
            <Text mb={textValueMargin} fontSize="0.813rem">
              IPI:
              {' '}
              {writer.ipi || undefinedLabel}
            </Text>
            <Text mb={textValueMargin} fontSize="0.813rem">
              PRO:
              {' '}
              {writer.pro?.name || undefinedLabel}
            </Text>
            {writer.selfPublisher?.name && (
              <Text mb={textValueMargin} fontSize="0.813rem">
                Self-Owned Publisher:
                {' '}
                {writer.selfPublisher?.name}
              </Text>
            )}
            {/* {writer.selfPublisher?.name && (
            <>
              <Text mb={textValueMargin} fontSize="0.813rem">
                IPI:
                {' '}
                {writer.selfPublisher?.ipi || undefinedLabel}
              </Text>
              <Text mb={textValueMargin} fontSize="0.813rem">
                PRO:
                {' '}
                {writer.selfPublisher?.pro || undefinedLabel}
              </Text>
              <Text mb={textValueMargin} fontSize="0.813rem">
                Address:
                {' '}
                {writer.selfPublisher?.address || undefinedLabel}
              </Text>
              <Text mb={textValueMargin} fontSize="0.813rem">
                Country:
                {' '}
                {writer.selfPublisher?.country || undefinedLabel}
              </Text>
            </>
            )} */}
          </Box>

          <BreakLine mb="1.25em" mt="1.25em" color="var(--panel-info-border)" />
        </Fragment>
      ))}

      <Text mb="0.813em" fontWeight="800">
        Publisher Information
      </Text>

      <Text mb={textValueMargin} fontSize="0.813rem">
        Name:
        {' '}
        {contractData.publisher?.name || undefinedLabel}
      </Text>
      <Text mb={textValueMargin} fontSize="0.813rem">
        Email:
        {' '}
        {contractData.publisher?.email || undefinedLabel}
      </Text>
      <Text mb={textValueMargin} fontSize="0.813rem">
        IPI:
        {' '}
        {contractData.publisher?.ipi || undefinedLabel}
      </Text>
      <Text mb={textValueMargin} fontSize="0.813rem">
        PRO:
        {' '}
        {contractData.publisher?.pro?.name || undefinedLabel}
      </Text>
      <Text mb={textValueMargin} fontSize="0.813rem">
        Address:
        {' '}
        {contractData.publisher?.address || undefinedLabel}
      </Text>
      <Text mb={textValueMargin} fontSize="0.813rem">
        Country:
        {' '}
        {contractData.publisher?.country || undefinedLabel}
      </Text>

      <BreakLine mb="1.25em" mt="1.25em" color="var(--panel-info-border)" />

      <Text mb="0.813em" fontWeight="800">
        Contract Terms
      </Text>

      <Text mb={textValueMargin} fontSize="0.813rem">
        Effective Dates:
        {' '}
        {`${
          contractData.startDate ? d(contractData.startDate) : undefinedLabel
        } - ${contractData.endDate ? d(contractData.endDate) : undefinedLabel}`}
      </Text>

      <Text mb={textValueMargin} fontSize="0.813rem">
        Deal Type:
        {' '}
        {contractData.type || undefinedLabel}
      </Text>

      <Text mb={textValueMargin} fontSize="0.813rem">
        Undivided Ownership Interest:
        {' '}
        {Number.isNaN(contractData.ownershipPercentage) ? undefinedLabel : `${contractData.ownershipPercentage}%`}
      </Text>

      <Text mb={textValueMargin} fontSize="0.813rem">
        Territory:
        {' '}
        {contractData.territory || undefinedLabel}
      </Text>

      <Text mb={textValueMargin} fontSize="0.813rem">
        Delivery Commitment:
        {' '}
        {contractData.deliveryCommitment?.included
          ? `${contractData.deliveryCommitment?.totalCompositions} compositions${contractData.deliveryCommitment?.qualifyingPercentage > 0 && ` of ${contractData.deliveryCommitment?.qualifyingPercentage}% ownership`}`
          : undefinedLabel}
      </Text>

      {contractData.deliveryCommitment?.included && (
      <Text mb={textValueMargin} fontSize="0.813rem">
        Combine Ownership Percentages:
        {' '}
        {contractData.deliveryCommitment?.aggregation?.allowed
          ? `Yes (${contractData.deliveryCommitment?.aggregation?.minimumPercentage > 0
            ? `at least ${contractData.deliveryCommitment?.aggregation?.minimumPercentage}% ownership`
            : 'no minimum ownership'})`
          : 'No'}
      </Text>
      )}

      <BreakLine mb="1.25em" mt="1.25em" color="var(--panel-info-border)" />
    </Box>
  );
}

AddedContractDataView.propTypes = {
  contractSummary: PropTypes.string.isRequired,
  contractData: PropTypes.object.isRequired,
};

export default AddedContractDataView;
