import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';

import BaseButton from '@ui-kit/buttons/baseButton';
import Box from '@ui-kit/box';
import Text from '@ui-kit/typography/text';
import Header from '@ui-kit/typography/header';
import AlertMessage from '@ui-kit/alert/Alert';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import { Fragment } from 'preact';
import PasswordValidationRow from '@distinct-components/feedback/passwordValidationRow';
import PasswordMatchesRow from '@distinct-components/feedback/passwordMatchesRow';

function AccountDetail({
  handleAccountDetail,
  error,
  isLoading,
  defaultFirstName,
  defaultLastName,
}) {
  const {
    register, handleSubmit, getValues, formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [charactersValid, setCharactersValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [passMaxLengthValid, setPassMaxLengthValid] = useState(true);
  const [numberValid, setNumberValid] = useState(false);
  const [specialValid, setSpecialValid] = useState(false);
  const [passwordMatchValid, setPasswordMatchValid] = useState(false);

  const checkPasswordMatch = () => {
    const created = getValues().password;
    const confirmed = getValues().confPassword;
    if (created.length > 0 && (created === confirmed)) {
      setPasswordMatchValid(true);
    } else {
      setPasswordMatchValid(false);
    }
  };

  const handleConfirmPassword = () => {
    checkPasswordMatch();
  };

  const handleValidation = (event) => {
    const { value } = event.target;
    if (value.length >= 8) {
      setCharactersValid(true);
    } else {
      setCharactersValid(false);
    }
    if (value.length >= 256) {
      setPassMaxLengthValid(false);
    } else {
      setPassMaxLengthValid(true);
    }
    if (value.match(/[A-Z]/)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (value.match(/[a-z]/)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }
    if (value.match(/\d/)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
    if (value.match(/[!@#$%^&*]/)) {
      setSpecialValid(true);
    } else {
      setSpecialValid(false);
    }
    checkPasswordMatch();
  };

  const isFormValid = () => isValid && charactersValid && uppercaseValid && lowercaseValid && numberValid && specialValid && passwordMatchValid && passMaxLengthValid;

  const onSubmit = (data) => {
    handleAccountDetail(data);
  };

  return (
    <Fragment>
      <Header mb="1em">Account Details</Header>
      <form id="accountForm" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <InputLabel label="First Name" />
        <BaseInput
          {...register('firstName', { required: true })}
          id="firstName"
          name="firstName"
          defaultValue={defaultFirstName}
          autoComplete="given-name"
          placeholder=""
          type="text"
          fluid
          mb="1.5rem"
        />
        <InputLabel label="Last Name" />
        <BaseInput
          {...register('lastName', { required: true })}
          id="lastName"
          name="lastName"
          defaultValue={defaultLastName}
          autoComplete="family-name"
          placeholder=""
          type="text"
          fluid
          mb="1.5rem"
        />
        <InputLabel label="Password" />
        <BaseInput
          {...register('password', { required: true })}
          id="password"
          name="password"
          autoComplete="new-password"
          placeholder="Create a Password"
          type="password"
          fluid
          onKeyUp={handleValidation}
        />
        <PasswordValidationRow
          mb="1em"
          charactersValid={charactersValid}
          uppercaseValid={uppercaseValid}
          lowercaseValid={lowercaseValid}
          numberValid={numberValid}
          specialValid={specialValid}
        />
        <InputLabel label="Confirm Password" />
        <BaseInput
          {...register('confPassword', { required: true })}
          id="confPassword"
          name="confPassword"
          autoComplete="off"
          placeholder="Confirm it..."
          type="password"
          fluid
          onKeyUp={handleConfirmPassword}
        />
        <PasswordMatchesRow
          mb="1.25rem"
          passwordMatches={passwordMatchValid}
        />

        {error
        && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
        />
        )}

        {!passMaxLengthValid
        && (
        <AlertMessage
          variant="negative"
          message="Password exceeds the 256 character limit"
          mb="1.5em"
        />
        )}

        <BaseButton
          mb="1.5em"
          type="submit"
          btnText="Next"
          disabled={!isFormValid()}
          fluid
          isLoading={isLoading}
        />
      </form>
      <Box mb="1em" display="flex" justifyContent="center">
        <Text fontSize="0.75rem" color="var(--text-medium-mute)">
          By Continuing You Agree to Our
          {' '}
          <a href="https://switchchord.com/terms" target="blank">Terms of Use</a>
          &nbsp;and&nbsp;
          <a href="https://switchchord.com/privacy" target="blank">Privacy Policy</a>
        </Text>
      </Box>
    </Fragment>
  );
}

AccountDetail.propTypes = {
  handleAccountDetail: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  defaultFirstName: PropTypes.string,
  defaultLastName: PropTypes.string,
};

AccountDetail.defaultProps = {
  defaultFirstName: '',
  defaultLastName: '',
};

export default AccountDetail;
