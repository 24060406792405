/* eslint-disable object-curly-newline */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';

import { postOrgContract } from '@api/restricted/org-contracts-api';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import SecondaryText from '@ui-kit/typography/secondaryText';
import Box from '@ui-kit/box';
import BreakLine from '@ui-kit/dividers/breakLine';
import LinkButton from '@ui-kit/buttons/linkButton/LinkButton';

import AddedContractDataEdit from './sections/addedContractDataEdit/AddedContractDataEdit';
import AddedContractDataView from './sections/addedContractDataView/AddedContractDataView';

function ReviewAddedContract({ contractFile, contractData, handleContractDataEdit, loadContracts, closeFunction }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);

  const onClickSubmitContractData = () => {
    setIsLoading(true);
    setError(null);

    postOrgContract(AppState.pubOrganization.id.value, contractFile, contractData)
      .then((response) => {
        if (response.status !== 200) {
          setIsLoading(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          loadContracts().then(() => {
            setIsLoading(false);
            closeFunction();
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <Box>
      <Header>Review and Save</Header>
      <SecondaryText mb="0.5rem" mt="0.75rem" fontSize="0.813rem">
        The following data was extracted from the agreement.
      </SecondaryText>

      <LinkButton
        type="button"
        mb="1.25rem"
        fontSize="0.813rem"
        color="var(--text-secondary)"
        btnText={isEditing ? 'Hide Input Fields' : 'Edit Extracted Data'}
        onClick={() => setIsEditing(!isEditing)}
      />

      <BreakLine mb="1.5em" color="var(--panel-info-border)" />

      {!isEditing ? (
        <AddedContractDataView
          contractSummary={contractData.summary}
          contractData={contractData}
          handleToggleEditView={() => setIsEditing(!isEditing)}
        />
      ) : (
        <AddedContractDataEdit
          contractData={contractData}
          handleContractDataEdit={handleContractDataEdit}
        />
      )}

      {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
          mt="1.5em"
        />
      )}

      {contractData && (
        <BaseButton
          mb="1.5em"
          btnText="Save and Continue"
          fluid
          isLoading={isLoading}
          onClick={onClickSubmitContractData}
        />
      )}
    </Box>
  );
}

ReviewAddedContract.propTypes = {
  contractFile: PropTypes.object.isRequired,
  contractData: PropTypes.object.isRequired,
  handleContractDataEdit: PropTypes.func.isRequired,
  loadContracts: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default ReviewAddedContract;
