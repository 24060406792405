/* eslint-disable object-curly-newline */
import PropTypes from 'prop-types';

import Box from '@ui-kit/box';
import BaseTextArea from '@ui-kit/inputs/baseTextArea/BaseTextArea';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';

function AddedContractDataEdit({ contractData, handleContractDataEdit }) {
  return (
    <Box>

      <InputLabel label="AI Summary" />
      <BaseTextArea
        placeholder="Contract Summary"
        type="textarea"
        defaultValue={contractData.summary}
        onChange={(e) => handleContractDataEdit('summary', e.target.value)}
        fluid
        maxLength="600"
        mb="1.5em"
        style={{ minHeight: '284px' }}
      />

      <Box>
        <InputLabel label="Reference Title" />
        <BaseInput
          id="refTitle"
          name="refTitle"
          placeholder="Title for reference..."
          defaultValue={contractData.name}
          onChange={(e) => handleContractDataEdit('name', e.target.value)}
          type="text"
          fluid
          mb="1em"
        />
      </Box>

    </Box>
  );
}

AddedContractDataEdit.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleContractDataEdit: PropTypes.func.isRequired,
};

export default AddedContractDataEdit;
