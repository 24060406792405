import { orgContractsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgContracts(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgContractsUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function getOrgContractDownload(orgId, contractId) {
  return refresh(async () => {
    const response = await fetch(`${orgContractsUrl}?${new URLSearchParams({
      org: orgId,
      contract: contractId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postOrgContract(orgId, file, data) {
  const formData = new FormData();

  formData.append('contract', file);
  formData.append('org', orgId);
  if (data) formData.append('contract_data', JSON.stringify(data));

  const response = await fetch(orgContractsUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: formData,
  });
  return response;
}

export async function deleteOrgContract(orgId, contractId) {
  const data = {
    org: orgId,
    contract: contractId,
  };
  return refresh(async () => {
    const response = await fetch(orgContractsUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
