/* eslint-disable no-param-reassign */
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import { route } from 'preact-router';
import AppState from '@state/AppState';

import loadOrganization from '@actions/loadOrganization';
import loadOrgActiveWriters from '@actions/loadOrgActiveWriters';
import { getOrgExports, getDownloadOrgExport, patchOrgExportRevert } from '@api/restricted/org-exports-api';

import d from '@hooks/useIsoDateFormat';
import useWindowDimensions from '@hooks/useWindowDimensions';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';
import DeleteConfirmationOverlay from '@shared-overlays/deleteConfirmationOverlay';

import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
// import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import TablePagination from '@ui-kit/table/tablePagination';
import Icon from '@ui-kit/icon';
import RevertIcon from '@assets/icons/revert-square.svg';
import DownloadIcon from '@assets/icons/download-doc.svg';

import CreateExport from './sections/createExport';

function PublisherExports({ uuid }) {
  const [isLoading, setIsLoading] = useState(true);
  const [currentExports, setCurrentExports] = useState([]);
  const [numCompsToExportCSV, setNumCompsToExportCSV] = useState(0);
  const [numCompsToExportCWR, setNumCompsToExportCWR] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showCreateExport, setShowCreateExport] = useState(false);
  const [selectedExport, setSelectedExport] = useState(null);
  const [revertError, setRevertError] = useState(null);
  const [isReverting, setIsReverting] = useState(false);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const useToastAlertError = (message) => {
    AppState.messages.toastMessage.value = { text: message, variant: 'error' };
  };

  const loadExports = async () => {
    setIsLoading(true);
    try {
      const response = await getOrgExports(AppState.pubOrganization.id.value);
      if (response.status === 200) {
        const json = await response.json();
        setCurrentExports(json.exports.filter((ex) => !ex.isReverted));
        setNumCompsToExportCSV(json.numCompsToExport.csv);
        setNumCompsToExportCWR(json.numCompsToExport.cwr);
        setIsLoading(false);
      } else {
        try {
          const json = await response.json();
          AppState.exceptions.errorPageError.value = `${json.error || response.statusText} when loading exports`;
          route('/error/exception', true);
        } catch {
          AppState.exceptions.errorPageError.value = `${response.statusText} when loading exports`;
          route('/error/exception', true);
        }
      }
    } catch (err) {
      AppState.exceptions.errorPageError.value = `${err.message} when loading exports`;
      route('/error/exception', true);
    }
  };

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }

    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadOrgActiveWriters(AppState.pubOrganization.id.value).then(() => {
        loadExports();
      });
    });
  }, []);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = currentExports.slice(indexOfFirst, indexOfLast);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onClickCreateExport = () => {
    setShowCreateExport(true);
  };

  const onClickRevertExport = (ex) => {
    setSelectedExport(ex);
  };

  const onClickDownloadExport = (ex) => {
    getDownloadOrgExport(AppState.pubOrganization.id.value, ex.id)
      .then((response) => {
        if (response.status === 200) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = ex.name;
            document.body.appendChild(a);
            a.click();
            a.remove();
          });
        } else {
          response.json().then((json) => {
            useToastAlertError(json.error || response.statusText);
          });
        }
      })
      .catch((err) => {
        useToastAlertError(err.message);
      });
  };

  const handleRevertExport = () => {
    setIsReverting(true);
    setRevertError('');

    patchOrgExportRevert(AppState.pubOrganization.id.value, selectedExport.id)
      .then((response) => {
        if (response.status === 200) {
          loadExports().then(() => {
            setSelectedExport(null);
            setIsReverting(false);
          });
        } else {
          response.json()
            .then((json) => setRevertError(json.error || response.statusText))
            .catch(() => setRevertError(response.statusText));
          setIsReverting(false);
        }
      })
      .catch((err) => {
        setIsReverting(false);
        setRevertError(err.message);
      });
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Exports"
        subTitle="Create data exports for finalized compositions"
        primaryAction={{ method: onClickCreateExport, text: '+ Export' }}
        isLoadingPage={isLoading}
      >
        <div ref={pageRef} />
        {currentExports.length === 0 ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="Data Exports"
              message="Export completed work data in CSV or CWR format"
              illustration="data"
              minHeight="38em"
            />
          </Card>
        ) : (
          <Fragment>
            <TableControls
              itemName="Export"
              tableData={currentExports}
              currentResults={currentResults}
              indexOfFirst={indexOfFirst}
              indexOfLast={indexOfLast}
              showSearch={false}
            />
            <Table mb="1em">
              <TableHeader>
                <TableCell>Export Type</TableCell>
                <TableCell>Exported By</TableCell>
                <TableCell>Exported On</TableCell>
                <TableCell align="right">Compositions Exported</TableCell>
                <TableCell>{' '}</TableCell>
              </TableHeader>
              {currentResults.map((ex) => (
                <TableRow>
                  <TableCell>
                    {ex.type}
                  </TableCell>
                  <TableCell>
                    {ex.createdBy}
                  </TableCell>
                  <TableCell>
                    {d(ex.createdAt)}
                  </TableCell>
                  <TableCell align="right">
                    {ex.numCompositions}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end">
                      <Icon size="1rem" mr="0.938rem" cursor onClick={() => onClickRevertExport(ex)}><RevertIcon /></Icon>
                      <Icon size="1rem" cursor onClick={() => onClickDownloadExport(ex)}><DownloadIcon /></Icon>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
            <TablePagination
              itemsPerPage={itemsPerPage}
              totalItems={currentExports.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Fragment>
        )}

      </PageCard>

      {showCreateExport
      && (
        <CreateExport
          handleClose={() => setShowCreateExport(false)}
          loadExports={loadExports}
          numCompsToExportCSV={numCompsToExportCSV}
          numCompsToExportCWR={numCompsToExportCWR}
        />
      )}

      {selectedExport
      && (
      <DeleteConfirmationOverlay
        headerLabel="Are you sure?"
        header="Revert Composition Export"
        explanation={`This export will be removed and the compositions will be marked as not exported. Total compositions in this export: ${selectedExport.numCompositions}`}
        closeFunction={() => setSelectedExport(null)}
        handleDelete={handleRevertExport}
        error={revertError}
        isLoading={isReverting}
        confirmationPhrase="Revert"
        buttonText="Confirm and Revert Export"
      />
      )}
    </CorePublisherLayout>
  );
}

PublisherExports.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherExports;
