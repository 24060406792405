/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-nested-ternary
import styled from 'styled-components';
import {
  space, variant, size,
} from 'styled-system';

export const StyledUtilityButton = styled.div(
  ({
    danger,
    isPerformingDestructiveAction,
    isTransparent,
  }) => `
    background: ${danger && !isTransparent ? '#920f43' : (isTransparent ? 'transparent' : 'var(--pill-base-bg)')};
    width: auto;
    color: ${danger ? 'var(--white)' : 'var(--text-primary)'};
    font-size: 0.813rem;
    font-weight: 500;
    min-width: auto;
    min-height: 34px;
    max-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
    overflow: hidden;
    cursor: ${isPerformingDestructiveAction ? 'not-allowed' : 'pointer'};
    border-radius: 8px;
    padding: ${danger ? '0rem 0.875rem 0rem 0.5rem' : '0rem 0.875rem 0rem 0.75rem'};
    ${space}
    ${size}
  `,
  variant({
    variants: {
      secondary: {
        background: 'transparent',
        border: '1px solid var(--pill-base-bg)',
        color: 'var(--text-primary)',
      },
    },
  }),
  space,
  size,
);
