/* eslint-disable object-curly-newline */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import AddContractFile from './sections/addContractFile';
import ReviewAddedContract from './sections/reviewAddedContract/ReviewAddedContract';

function PublisherAddContract({ loadContracts, closeFunction }) {
  const [isReviewData, setIsReviewData] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [contractData, setContractData] = useState();

  const handleContractDataEdit = (key, value) => {
    setContractData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleContractData = (data) => {
    setContractData(data);
    setIsReviewData(true);
  };

  return (
    <BaseEditOverlay formWidth="24rem" closeFunction={closeFunction}>

      {!isReviewData ? (
        <AddContractFile contractFile={contractFile} setContractFile={setContractFile} handleContractData={handleContractData} />
      ) : (
        <ReviewAddedContract
          contractFile={contractFile}
          contractData={contractData}
          handleContractDataEdit={handleContractDataEdit}
          loadContracts={loadContracts}
          closeFunction={closeFunction}
        />
      )}
    </BaseEditOverlay>
  );
}

PublisherAddContract.propTypes = {
  loadContracts: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default PublisherAddContract;
