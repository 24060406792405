import { orgExportsUrl } from '../urls';
import refresh from '../refresh';

export async function getOrgExports(orgId) {
  return refresh(async () => {
    const response = await fetch(`${orgExportsUrl}?${new URLSearchParams({
      org: orgId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function getDownloadOrgExport(orgId, exportId) {
  return refresh(async () => {
    const response = await fetch(`${orgExportsUrl}?${new URLSearchParams({
      org: orgId,
      export: exportId,
    })}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return response;
  });
}

export async function postOrgExport(orgId, type, allCompositions, connectionId, writerId, fromDate, toDate) {
  const data = {
    org: orgId,
    type,
    allCompositions,
    connection: connectionId,
    artist: writerId,
    from: fromDate,
    to: toDate,
  };
  return refresh(async () => {
    const response = await fetch(orgExportsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}

export async function patchOrgExportRevert(orgId, exportId) {
  const data = {
    org: orgId,
    export: exportId,
  };
  return refresh(async () => {
    const response = await fetch(orgExportsUrl, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
    return response;
  });
}
