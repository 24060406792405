/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import TableCell from '@ui-kit/table/tableCell';

export const StyledAiSummaryCell = styled(TableCell)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.6;
    font-size: 0.75rem;
    color: var(--text-primary);
`;
