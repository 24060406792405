/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { space } from 'styled-system';

export const AiSummaryCard = styled.div`
  background: var(--ai-summary-card-bg);
  padding: 1rem;
  border-radius: 0.8rem;
  ${space}
`;
