/* eslint-disable object-curly-newline */
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { Fragment } from 'preact';
import AppState from '@state';

import loadOrganization from '@actions/loadOrganization';
import { getOrgContracts } from '@api/restricted/org-contracts-api';

import useWindowDimensions from '@hooks/useWindowDimensions';
import useErrorOverlay from '@hooks/useErrorOverlay';
import d from '@hooks/useIsoDateFormat';

import CorePublisherLayout from '@layouts/corePublisher';
import PageCard from '@layouts/pageCard';
import Card from '@distinct-components/cards/baseCard';
import BaseEmptyState from '@distinct-components/feedback/baseEmptyState';
import Text from '@ui-kit/typography/text';
import TableControls from '@ui-kit/table/tableControls';
import Table from '@ui-kit/table';
import TableHeader from '@ui-kit/table/tableHeader';
import TableRow from '@ui-kit/table/tableRow';
import TableCell from '@ui-kit/table/tableCell';
import Box from '@ui-kit/box';
import TablePagination from '@ui-kit/table/tablePagination';

import { StyledAiSummaryCell } from './PublisherContractsStyles';

import PublisherAddContract from './publisherAddContract/PublisherAddContract';
import PublisherViewContract from './publisherViewContract/PublisherViewContract';

function PublisherContracts({ uuid }) {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isAddingContract, setIsAddingContract] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);

  const [allContracts, setAllContracts] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { height } = useWindowDimensions();
  const pageRef = useRef(null);

  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentResults = contracts.slice(indexOfFirst, indexOfLast);

  const loadContracts = async () => {
    try {
      const response = await getOrgContracts(AppState.pubOrganization.id.value);
      if (response.status !== 200) {
        const json = await response.json();
        useErrorOverlay({ errorMessage: `${json.error || response.statusText} when loading contracts` });
      } else {
        const json = await response.json();
        setAllContracts(json.contracts);
        setContracts(json.contracts);
        setSearchActive(false);
      }
    } catch (err) {
      useErrorOverlay({ errorMessage: `${err.message} when loading contracts` });
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {
      pageRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
    }, 200);
  };

  const onSearchResults = (query) => {
    const results = [];
    setCurrentPage(1);
    if (query) {
      allContracts.forEach((contract) => {
        if (contract.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) || contract.summary.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
          results.push(contract);
        }
      });
      setContracts(results);
      setSearchActive(true);
    } else {
      setContracts(allContracts);
      setSearchActive(false);
    }
  };

  useEffect(() => {
    if (height < 1080) {
      setItemsPerPage(7);
    }
    loadOrganization(uuid, { forcedLoad: false }).then(() => {
      loadContracts().then(() => {
        setIsLoadingPage(false);
      });
    });
  }, []);

  const onClickAddContract = () => {
    setIsAddingContract(true);
  };

  return (
    <CorePublisherLayout>
      <PageCard
        title="Contracts"
        primaryAction={{ method: onClickAddContract, text: '+ Contract' }}
        isLoadingPage={isLoadingPage}
      >
        <div ref={pageRef} />
        {(contracts.length === 0 && !searchActive) ? (
          <Card variant="panel" mt="1.5em">
            <BaseEmptyState
              title="No Contracts Analyzed"
              message="Add contracts to easily find and reference your agreements"
              action={onClickAddContract}
              actionText="Add Contract"
              illustration="contracts"
              minHeight="38em"
            />
          </Card>
        ) : (
          <Fragment>
            <TableControls
              itemName="Contract"
              tableData={contracts}
              currentResults={currentResults}
              indexOfFirst={indexOfFirst}
              indexOfLast={indexOfLast}
              showSearch
              searchActive={searchActive}
              searchResults={contracts}
              handleSearch={onSearchResults}
            />
            <Table mb="1em">
              <TableHeader>
                <TableCell>Contract</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Effective Dates</TableCell>
                <TableCell widthPercent="60%">Summary</TableCell>
              </TableHeader>
              {currentResults.map((contract) => (
                <TableRow onClick={() => setSelectedContract(contract)} cursor>
                  <TableCell>
                    <Text
                      fontSize="0.813rem"
                      color="var(--text-primary)"
                      fontWeight="600"
                    >
                      {contract.name}
                    </Text>
                  </TableCell>
                  <TableCell mobileLabel="Type:">{contract.data.type}</TableCell>
                  <TableCell mobileLabel="Effective Dates:">
                    {(contract.data.startDate && contract.data.endDate) && `${d(contract.data.startDate)} - ${d(contract.data.endDate)}`}
                    {(!contract.data.startDate && contract.data.endDate) && `Until ${d(contract.data.endDate)}`}
                    {(contract.data.startDate && !contract.data.endDate) && `${d(contract.data.startDate)} - Until Revoked`}
                    {(!contract.data.startDate && !contract.data.endDate) && 'Not Found'}
                  </TableCell>
                  <StyledAiSummaryCell mobileLabel="Summary:" widthPercent="60%">
                    {contract.summary}
                  </StyledAiSummaryCell>
                </TableRow>
              ))}
              {currentResults.length === 0 && (
                <Box>
                  <Text color="var(--text-muted-panel)">
                    No Contracts Match Your Search
                  </Text>
                </Box>
              )}
            </Table>
            <TablePagination
              itemsPerPage={itemsPerPage}
              totalItems={contracts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </Fragment>
        )}
      </PageCard>

      {isAddingContract && (
        <PublisherAddContract
          uuid={uuid}
          loadContracts={loadContracts}
          closeFunction={() => setIsAddingContract(false)}
        />
      )}

      {selectedContract && (
        <PublisherViewContract
          contract={selectedContract}
          loadContracts={loadContracts}
          closeFunction={() => setSelectedContract(null)}
        />
      )}
    </CorePublisherLayout>
  );
}

PublisherContracts.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PublisherContracts;
