import { useForm } from 'react-hook-form';
import { useState } from 'preact/hooks';
import { Fragment } from 'preact';
import { route } from 'preact-router';

import { patchForgotPassword } from '@api/public/forgot-password-api';

import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import AlertMessage from '@ui-kit/alert/Alert';
import Text from '@ui-kit/typography/text';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import FullScreenModal from '@layouts/full-screen/full-screen-modal';
import PasswordValidationRow from '@distinct-components/feedback/passwordValidationRow';
import PasswordMatchesRow from '@distinct-components/feedback/passwordMatchesRow';

function ResetPassword() {
  const {
    register, handleSubmit, getValues, formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [charactersValid, setCharactersValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [passMaxLengthValid, setPassMaxLengthValid] = useState(true);
  const [numberValid, setNumberValid] = useState(false);
  const [specialValid, setSpecialValid] = useState(false);
  const [passwordMatchValid, setPasswordMatchValid] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const checkPasswordMatch = () => {
    const created = getValues().password;
    const confirmed = getValues().confPassword;
    if (created.length > 0 && (created === confirmed)) {
      setPasswordMatchValid(true);
    } else {
      setPasswordMatchValid(false);
    }
  };

  const handleConfirmPassword = () => {
    checkPasswordMatch();
  };

  const handleValidation = (event) => {
    const { value } = event.target;
    if (value.length >= 8) {
      setCharactersValid(true);
    } else {
      setCharactersValid(false);
    }
    if (value.length >= 256) {
      setPassMaxLengthValid(false);
    } else {
      setPassMaxLengthValid(true);
    }
    if (value.match(/[A-Z]/)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
    if (value.match(/[a-z]/)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }
    if (value.match(/\d/)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
    if (value.match(/[!@#$%^&*]/)) {
      setSpecialValid(true);
    } else {
      setSpecialValid(false);
    }
    checkPasswordMatch();
  };

  const isFormValid = () => isValid && charactersValid && uppercaseValid && lowercaseValid && numberValid && specialValid && passwordMatchValid && passMaxLengthValid;

  const onSubmit = (data) => {
    setIsLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    patchForgotPassword(
      queryParams.get('email'),
      queryParams.get('token'),
      data.password,
    ).then((response) => {
      if (response.status !== 200) {
        response.json()
          .then((json) => setError(json.error ? json.error : response.statusText))
          .catch(() => setError(response.statusText));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError('');
        setIsPasswordReset(true);
      }
    }).catch((err) => {
      setIsLoading(false);
      setError(err.message);
    });
  };

  const handleGoLogin = () => {
    route('/login');
  };

  return (
    <FullScreenModal formWidth="23em">
      {
      {
        false:
        (
          <Fragment>
            <Header mb="0.5em">Reset Password</Header>
            <Text variant="thinPrompt" mb="1.5em">
              Create and confirm your new password
            </Text>
            <form id="accountForm" onSubmit={handleSubmit(onSubmit)} autoComplete="on">
              <InputLabel label="New Password" />
              <BaseInput
                {...register('password', { required: true })}
                id="password"
                name="password"
                placeholder="Create a New Password"
                type="password"
                fluid
                onKeyUp={handleValidation}
              />
              <PasswordValidationRow
                mb="1.25rem"
                charactersValid={charactersValid}
                uppercaseValid={uppercaseValid}
                lowercaseValid={lowercaseValid}
                numberValid={numberValid}
                specialValid={specialValid}
              />
              <InputLabel label="Confirm New Password" />
              <BaseInput
                {...register('confPassword', { required: true })}
                id="confPassword"
                name="confPassword"
                placeholder="Confirm it..."
                type="password"
                fluid
                onKeyUp={handleConfirmPassword}
              />
              <PasswordMatchesRow
                mb="1.25rem"
                passwordMatches={passwordMatchValid}
              />

              {error
                && (
                <AlertMessage
                  variant="negative"
                  message={error}
                  mb="1.5em"
                />
                )}

              {!passMaxLengthValid
                && (
                <AlertMessage
                  variant="negative"
                  message="Password exceeds the 256 character limit"
                  mb="1.5em"
                />
                )}

              <BaseButton
                mb="1.5em"
                type="submit"
                btnText="Submit"
                disabled={!isFormValid()}
                fluid
                isLoading={isLoading}
              />
            </form>
          </Fragment>
        ),
        true:
        (
          <Fragment>
            <Header mb="0.5em">Password Reset</Header>
            <Text variant="thinPrompt" mb="2em">
              Your new password is ready to go.
            </Text>
            <BaseButton
              mb="1.5em"
              onClick={handleGoLogin}
              btnText="Continue to Login"
              fluid
            />
          </Fragment>
        ),
      }[isPasswordReset]
    }
    </FullScreenModal>
  );
}

export default ResetPassword;
