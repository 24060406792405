/* eslint-disable no-nested-ternary */
import { useState, useEffect, useRef } from 'preact/hooks';
import { Fragment } from 'preact';
import PropTypes from 'prop-types';
import AppState from '@state';
import domtoimage from 'dom-to-image';
import copy from 'copy-to-clipboard';

import getCompositionIdentityToken from '@api/restricted/composition-identity-token-api';

import AlertMessage from '@ui-kit/alert/Alert';
import Box from '@ui-kit/box';
import ButtonSegment from '@ui-kit/buttons/buttonSegment/ButtonSegment';
import ConversationPrompt from '@distinct-components/conversations/conversationPrompt';
import Spinner from '@ui-kit/loaders/Spinner';

import ConversationExplanation from '@distinct-components/conversations/conversationExplanation';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';

import { COMPOSITION_EXPLANATIONS } from '@constants/supportingCopy';

import WorkspaceIdentityCard from './items/workspaceIdentityCard/WorkspaceIdentityCard';

function ShareWorkspace({
  closeFunction,
  isPublisherView,
  composition,
}) {
  const [mode, setMode] = useState('Loading');
  const [identityLink, setIdentityLink] = useState('');
  const [linkError, setLinkError] = useState(null);
  const shareCardRef = useRef();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileSharing, setMobileSharing] = useState(false);

  const orgId = isPublisherView ? AppState.pubOrganization.id.value : null;
  const compositionUUID = isPublisherView ? composition.uuid : AppState.composition.uuid.value;
  const compositionName = isPublisherView ? composition.name : AppState.composition.name.value;
  const compositionDID = isPublisherView ? composition.did : AppState.composition.did.value;
  const shareableVersion = isPublisherView
    ? (composition.finalizedDate ? composition.version : composition.version - 1)
    : AppState.composition.shareableVersion.value;
  const numWriters = isPublisherView ? composition.writers.length : AppState.workspaceAccounts.value.filter((acc) => acc.isWriter).length;

  useEffect(async () => {
    if (shareableVersion > 0 && compositionUUID) {
      if (navigator.canShare) {
        setMobileSharing(true);
      } else {
        setMobileSharing(false);
      }
      getCompositionIdentityToken(orgId, compositionUUID, shareableVersion).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setIdentityLink(json.link);
            setMode('CanShare');
          }).catch((err) => {
            setLinkError(err.message);
            setMode('LinkError');
          });
        } else {
          response.json()
            .then((json) => setLinkError(json.error || response.statusText))
            .catch(() => setLinkError(response.statusText));
          setMode('LinkError');
        }
      }).catch((err) => {
        setLinkError(err.message);
        setMode('LinkError');
      });
    } else {
      setMode('CantShare');
    }
  }, []);

  const handleCopyToClipBoard = async () => {
    setIsLoading(true);
    copy(identityLink);
    AppState.messages.toastMessage.value = { text: 'Share Link Copied', variant: 'success' };
    setIsLoading(false);
  };

  const handleEmailShare = () => {
    window.location = `mailto:?subject=Composition%20Identity%20Link%3A%20${compositionName}&body=Composition%20and%20Splits%20are%20available%20to%20view%20with%20the%20secure%20link%20below.%20%0A%0A%${identityLink}%0A%0ASwitchchord%20creates%20persistent%20and%20verifiable%20identities%20for%20compositions.%20%20The%20link%20above%20will%20show%20the%20current%20state%20of%20the%20copyright.`;
  };

  const handleSaveShareCard = () => {
    setIsLoading(true);
    const element = shareCardRef.current;
    const name = `${compositionName}- Composition Identity`;
    domtoimage.toBlob(element)
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const onMobileShare = async () => {
    setIsLoading(true);
    const node = shareCardRef.current;
    const scale = 750 / node.offsetWidth;
    domtoimage.toBlob(node, {
      height: node.offsetHeight * scale,
      width: node.offsetWidth * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${node.offsetWidth}px`,
        height: `${node.offsetHeight}px`,
      },
    })
      .then((blob) => {
        const filesArray = [
          new File(
            [blob],
            'identity.jpg',
            {
              type: 'image/jpeg',
              lastModified: new Date().getTime(),
            },
          ),
        ];
        const shareData = {
          title: 'Composition Identity',
          files: filesArray,
          url: identityLink,
        };
        navigator.share(shareData);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  return (
    <BaseEditOverlay formCentered closeFunction={closeFunction}>
      {{
        Loading: (
          <Box width="100%" display="flex" justifyContent="center" height="calc(100vh - 200px)" alignItems="center">
            <Spinner size="2em" variant="page" />
          </Box>
        ),

        CantShare: (
          <Fragment>
            <ConversationPrompt
              primaryMessage="Not ready to share"
              promptLabel="Share Link"
              labelColor="var(--purple-300)"
              mb="1em"
            />
            {numWriters > 1
              ? <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.shareWorkspace.noExecutedContract} truncateAtCharacter={500} />
              : <ConversationExplanation mb="2em" explanation={COMPOSITION_EXPLANATIONS.shareWorkspace.singleWriterNotLocked} truncateAtCharacter={500} />}
          </Fragment>
        ),

        LinkError: (
          <Fragment>
            <ConversationPrompt
              primaryMessage="Something went wrong..."
              promptLabel="Share Link"
              labelColor="var(--red-300)"
              mb="1em"
            />
            <AlertMessage
              variant="negative"
              message={linkError}
              mb="1.5em"
            />
          </Fragment>
        ),

        CanShare: (
          <Box>
            <div id="card" ref={shareCardRef}>
              <WorkspaceIdentityCard shareLink={identityLink} compositionName={compositionName} compositionDID={compositionDID} />
            </div>

            {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
            />
            )}

            {mobileSharing ? (
              <Box display="flex" mb="1.75em" width="100%">
                <ButtonSegment onClick={handleCopyToClipBoard} disabled={isLoading} btnText="Copy Share Link" variant="solo" mr="1em" active fluid />
                <ButtonSegment onClick={onMobileShare} btnText="Mobile Share" disabled={isLoading} variant="solo" active fluid />
              </Box>
            ) : (
              <Box display="flex" mb="1.75em" width="100%">
                <ButtonSegment onClick={handleCopyToClipBoard} disabled={isLoading} btnText="Copy Link" variant="solo" mr="1em" active fluid />
                <ButtonSegment onClick={handleEmailShare} disabled={isLoading} btnText="Email" variant="solo" mr="1em" active fluid />
                <ButtonSegment onClick={handleSaveShareCard} disabled={isLoading} btnText="Download" variant="solo" active fluid />
              </Box>
            )}
          </Box>
        ),
      }[mode]}
    </BaseEditOverlay>
  );
}

ShareWorkspace.propTypes = {
  closeFunction: PropTypes.func.isRequired,
  isPublisherView: PropTypes.bool,
  composition: PropTypes.object,
};

ShareWorkspace.defaultProps = {
  isPublisherView: false,
  composition: {},
};

export default ShareWorkspace;
