/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import Text from '@ui-kit/typography/text';
import Box from '@ui-kit/box';
import InputLabel from '@ui-kit/inputs/inputLabel';
import BaseInput from '@ui-kit/inputs/baseInput/BaseInput';
import Icon from '@ui-kit/icon';
import ClearIcon from '@assets/icons/close-solo.svg';

import {
  ResultsWrapper,
  ResultRow,
  Result,
  StyledBaseInput,
  SelectedWrapper,
  ClearSearchChip,
} from './BaseTypeAheadStyles';

function BaseTypeAhead({
  data,
  handleChange,
  initialValue,
  label,
  id,
}) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(initialValue);
  const [focusedNumber, setFocusedNumber] = useState(-1);
  const [focusedItem, setFocusedItem] = useState();
  const [results, setResults] = useState();

  const onSelectItem = (d) => {
    setSelectedItem(d.name);
    handleChange(d.name);
    setSearchValue('');
  };

  const clearSelection = () => {
    setSelectedItem(null);
    handleChange(null);
  };

  const onSearch = (event) => {
    const query = event.target.value;
    setSearchValue(query);
    if (query) {
      const resultsToShow = data.filter((d) => d.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
      setResults(resultsToShow);
      setFocusedNumber(-1);
      setFocusedItem(null);
    } else {
      setResults(null);
      setFocusedNumber(-1);
      setFocusedItem(null);
    }
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 40 && focusedNumber + 1 < results.length) {
      const focused = results[focusedNumber + 1].name;
      setFocusedNumber(focusedNumber + 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 38 && focusedNumber - 1 >= 0) {
      const focused = results[focusedNumber - 1].name;
      setFocusedNumber(focusedNumber - 1);
      setFocusedItem(focused);
      return;
    }
    if (event.keyCode === 13) {
      setSelectedItem(focusedItem);
      handleChange(focusedItem);
      setSearchValue('');
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      {label && <InputLabel label={label} />}
      {selectedItem ? (
        <SelectedWrapper>
          <StyledBaseInput
            value={selectedItem}
            disabled
            fluid
            mb="1.5em"
          />
          <ClearSearchChip onClick={clearSelection}>
            <Icon size="0.65em" color="var(--purple-300)" cursor><ClearIcon /></Icon>
          </ClearSearchChip>
        </SelectedWrapper>
      ) : (
        <BaseInput
          placeholder="Start typing..."
          id={id}
          value={searchValue}
          fluid
          mb="1.5em"
          autoComplete="off"
          onKeyDown={onKeyDown}
          onChange={onSearch}
          onBlur={() => setSearchValue('')}
        />
      )}
      {searchValue.length > 0
      && (
      <ResultsWrapper>
        {results && results.map((d) => (
          <ResultRow onClick={() => onSelectItem(d)} onMouseDown={(e) => e.preventDefault()} style={d.name === focusedItem && { background: 'var(--input-menu-highlight)' }}>
            <Result>
              {d.name}
            </Result>
          </ResultRow>
        ))}
        {results.length === 0
          && <Text p="1em" color="var(--text-muted-panel)">No Results</Text>}
      </ResultsWrapper>
      )}
    </Box>
  );
}

BaseTypeAhead.propTypes = {
  data: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
};

BaseTypeAhead.defaultProps = {
  label: '',
  initialValue: '',
};

export default BaseTypeAhead;
