/* eslint-disable object-curly-newline */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';

import { postOrgContract } from '@api/restricted/org-contracts-api';

import Text from '@ui-kit/typography/text';
import DragAndDrop from '@distinct-components/files/dragAndDrop';
import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import Header from '@ui-kit/typography/header';
import SecondaryText from '@ui-kit/typography/secondaryText';
import Box from '@ui-kit/box';
import AiLoadingAnimation from '@components/animations/ai/AiLoadingAnimation';

function AddContractFile({ contractFile, setContractFile, handleContractData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddFile = (files) => {
    setError(null);
    const fileToAdd = files[0];
    if (!fileToAdd || !fileToAdd.name.endsWith('.pdf')) {
      setError('Invalid file');
      setContractFile(null);
      return;
    }
    setContractFile(fileToAdd);
  };

  const handleSubmitFile = () => {
    setIsLoading(true);
    setError(null);

    postOrgContract(AppState.pubOrganization.id.value, contractFile)
      .then((response) => {
        if (response.status !== 200) {
          setIsLoading(false);
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
        } else {
          response.json().then((json) => {
            setIsLoading(false);
            handleContractData(json.data);
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.message);
      });
  };

  return (
    <Box>
      {isLoading ? (
        <Box mt="4rem" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <AiLoadingAnimation />
          <SecondaryText mt="1.5em" fontSize="0.813rem">
            Analyzing Contract...
          </SecondaryText>
        </Box>
      ) : (
        <Box>
          <Header>Analyze Contract</Header>
          <SecondaryText mb="1em" mt="0.75rem" fontSize="0.813rem">
            We use AI to summarize the contract and store verifiable data
            records for future use.
          </SecondaryText>
          <SecondaryText mb="1em" mt="0.75rem" fontSize="0.813rem">
            Upload the PDF version of any Songwriter or Administration
            Agreement.
          </SecondaryText>
          <DragAndDrop
            handleFilesAdded={handleAddFile}
            disabled={isLoading}
            id="file-drop"
          />

          {contractFile && (
            <Text mb="1em" fontSize="0.813rem">
              File Added:
              {' '}
              {contractFile.name}
            </Text>
          )}

          {error && (
            <AlertMessage
              variant="negative"
              message={error}
              mb="1.5em"
              mt="1.5em"
            />
          )}

          {contractFile && (
            <BaseButton
              mb="1.5em"
              btnText="Analyze"
              fluid
              isLoading={isLoading}
              onClick={handleSubmitFile}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

AddContractFile.propTypes = {
  contractFile: PropTypes.object,
  setContractFile: PropTypes.func.isRequired,
  handleContractData: PropTypes.func.isRequired,
};

AddContractFile.defaultProps = {
  contractFile: null,
};

export default AddContractFile;
