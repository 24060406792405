/* eslint-disable object-curly-newline */
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import AppState from '@state';

import { getOrgContractDownload, deleteOrgContract } from '@api/restricted/org-contracts-api';

import AlertMessage from '@ui-kit/alert/Alert';
import BaseButton from '@ui-kit/buttons/baseButton';
import BreakLine from '@ui-kit/dividers/breakLine';
import Header from '@ui-kit/typography/header';

import BaseEditOverlay from '@layouts/full-screen/baseEditOverlay';
import ConfirmDeleteUtilityBtn from '@components/distinct-components/distinctButtons/confirmDeleteUtilityBtn/ConfirmDeleteUtilityBtn';

import AddedContractDataView from '../publisherAddContract/sections/reviewAddedContract/sections/addedContractDataView/AddedContractDataView';

function PublisherViewContract({ contract, loadContracts, closeFunction }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  const handleDownloadContract = () => {
    setError(null);
    setIsDownloading(true);

    getOrgContractDownload(AppState.pubOrganization.id.value, contract.id)
      .then((response) => {
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsDownloading(false);
        } else {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = contract.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            setIsDownloading(false);
          });
        }
      })
      .catch((err) => {
        setIsDownloading(false);
        setError(err.message);
      });
  };

  const handleDeleteContract = () => {
    setError(null);
    setIsDeleting(true);

    deleteOrgContract(AppState.pubOrganization.id.value, contract.id)
      .then((response) => {
        if (response.status !== 200) {
          response.json()
            .then((json) => setError(json.error || response.statusText))
            .catch(() => setError(response.statusText));
          setIsDeleting(false);
        } else {
          loadContracts().then(() => {
            setIsDeleting(false);
            closeFunction();
          });
        }
      })
      .catch((err) => {
        setIsDeleting(false);
        setError(err.message);
      });
  };

  return (
    <BaseEditOverlay formWidth="46rem" closeFunction={closeFunction}>
      <Header mb="0.75rem">{contract.name}</Header>

      <BreakLine mb="1.5em" color="var(--panel-info-border)" />

      <AddedContractDataView
        contractSummary={contract.summary}
        contractData={contract.data}
      />

      {error && (
        <AlertMessage
          variant="negative"
          message={error}
          mb="1.5em"
          mt="1.5em"
        />
      )}

      <BaseButton
        mb="1.5em"
        btnText="Download"
        fluid
        isLoading={isDownloading}
        onClick={handleDownloadContract}
      />
      <ConfirmDeleteUtilityBtn
        destructiveFunction={handleDeleteContract}
        isPerformingDestructiveAction={isDeleting}
        isTransparent
      />
    </BaseEditOverlay>
  );
}

PublisherViewContract.propTypes = {
  contract: PropTypes.object.isRequired,
  loadContracts: PropTypes.func.isRequired,
  closeFunction: PropTypes.func.isRequired,
};

export default PublisherViewContract;
